<template>
  <div class="Genius_Brain">
    <div class="content">
      <h2>Genius Brain</h2>
      <h3>通过赋予硬件强大的“外脑”，使硬件和系统更加智能</h3>
      <ul>
        <li>
          <img src="../assets/imgs/ucode.png" />
          <div class="text">
            <h2>uCode（在线版）</h2>
            <p>趣味编程，快乐创造，从uCode开始！</p>
            <div class="button" v-if="!proToken" @click="notice">立即创作</div>
            <div class="button" v-else>
              <a rel="noreferrer" :href="uCodeUrl" target="_blank">立即创作</a>
            </div>
          </div>
        </li>
        <li>
          <img src="../assets/imgs/jupyter.png" />
          <div class="text">
            <h2>Genius Jupyter</h2>
            <p>iPython交互式笔记本，开启你的创作灵感！</p>
            <div class="button" v-if="!proToken" @click="notice">立即创作</div>
            <div class="button" v-else>
              <a
                href="https://sso.changeducation.cn/html/users/ssojupyter.html"
                target="_blank"
                >立即创作</a
              >
            </div>
          </div>
        </li>
        <li>
          <img src="../assets/imgs/suanfa.png" />
          <div class="text">
            <h2>Genius算法引擎</h2>
            <p>在Genius算法引擎，开始你的AI学习之旅！</p>
            <div class="button" @click="goClickt()">立即创作</div>
          </div>
        </li>
        <li>
          <img src="../assets/imgs/ucode.png" />
          <div class="text">
            <h2>uCode（离线版）</h2>
            <p>趣味编程，快乐创造，从uCode开始！</p>
            <div class="button">
              <a
                href="https://aitech.qingsteam.cn/software/uCode_Setup.zip"
                download
                >立即下载</a
              >
            </div>
          </div>
        </li>
        <li>
          <img src="../assets/imgs/moniqi.png" />
          <div class="text">
            <h2>悟空模拟器</h2>
            <p>使用悟空模拟器，模拟真实的悟空！</p>
            <div class="button">
              <a
                href="https://aitech.qingsteam.cn/download/robot.1.0.0.30.exe"
                download
                >立即下载</a
              >
            </div>
          </div>
        </li>
        <!-- <li>
          <img src="../assets/imgs/geniusclass.png" />
          <div class="text">
            <h2>Genius class（教师端）</h2>
            <p>使用Genius class(教师端)，备课授课更轻松！</p>
            <div class="button">
              <a
                href="https://aitech.qingsteam.cn/Genius%20Class%20%E6%95%99%E5%B8%88%E7%AB%AF.exe"
                download
                >立即下载</a
              >
            </div>
          </div>
        </li> -->
        <!-- <li>
          <img src="../assets/imgs/geniusclass.png">
          <div class="text">
              <h2>Genius class（学生端）</h2>
              <p>使用Genius class(学生端)，从此妈妈再也不用担心我的学习了呢！</p>
              <div class="button">立即下载</div>
          </div>
        </li> -->
        <!-- <li>
          <img src="../assets/imgs/geniuscar.png" />
          <div class="text">
            <h2>Genius Car模拟器</h2>
            <p>暂无</p>
            <div class="button button2">敬请期待</div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { Login } from "@/assets/js/login.js";
import { queryUcodeUrl } from "@/apis/Home.js";
import { mapState } from "vuex";
import { Message } from "ant-design-vue";
import axios from "axios";

export default {
  name: "Genius_Brain",
  data() {
    return {
      uCodeUrl: "",
    };
  },
  computed: {
    ...mapState({
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
    }),
  },
  created() {
    this.getUcodeUrl();
  },
  methods: {
    goClickt: function() {
      let url = "https://lab.qingsteam.cn/#/user";
      window.open(url);
    },

    getUcodeUrl() {
      let url = process.env.VUE_APP_LOGIN_Url;
      axios({
        method: "post",
        url: `${url}/getUBTToken`,
        headers: {
          sessionToken: this.proToken,
        },
        withCredentials: true, //表示允许跨域使用凭证cookie
      }).then((res) => {
        if (res.data.status == 0) {
          let url = res.data.result.data.ubtURL;
          this.uCodeUrl = url;
        }
      });
    },

    // 提示并跳转登录
    notice() {
      Message.warning("请登录", 1, () => {
        Login();
      });
    },
  },
};
</script>

<style scoped>
.Genius_Brain {
  width: 100%;
  height: auto;
  overflow-y: hidden;
  background: url(../assets/imgs/brain_bg.png) top no-repeat;
  min-height: calc(100vh -158px);
  max-height: auto;
  max-height: auto;
  min-width: 1200px;
}
.Genius_Brain .content {
  width: 1200px;
  height: auto;
  overflow-y: hidden;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #fff;
  padding: 50px;
  text-align: center;
}
.Genius_Brain .content > h2 {
  color: #606978;
  font-size: 36px;
  padding-bottom: 15px;
}
.Genius_Brain .content > h3 {
  color: #9aa7b9;
  font-size: 20px;
}
.content > ul {
  margin-left: -20px;
  margin-top: 50px;
  text-align: left;
  height: auto;
  overflow-y: hidden;
  padding-top: 15px;
}
.content > ul > li {
  width: 525px;
  height: 176px;
  background: #fff;
  box-shadow: 3px 5px 14px 0px rgba(97, 97, 97, 0.1);
  border-radius: 8px;
  float: left;
  margin-left: 30px;
  padding: 20px;
  margin-bottom: 40px;
  transition: all 0.4s ease;
}
.content>ul>li:hover{
    transform: translateY(-10px);
    box-shadow: 3px 5px 14px 0px rgba(0, 0, 0, 0.2);
}
.content>ul>li:hover .button{ 
    background: #5D5AEE;
    color: #fff;
}
.content>ul>li:hover .button a{ 
    color: #fff;
}
.content > ul > li > img {
  float: left;
  width: 109px;
}
.content > ul > li .text {
  float: left;
  margin-left: 30px;
  width: 320px;
  height: 135px;
  position: relative;
}
.text > h2 {
  color: #333a48;
  font-size: 20px;
  padding-bottom: 5px;
}
.text > p {
  color: #606978;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.text .button {
  width: 140px;
  height: 40px;
  border-radius: 21px;
  border: 1.5px solid rgba(93, 90, 237, 1);
  font-size: 16px;
  font-weight: 500;
  color: rgba(93, 90, 237, 1);
  line-height: 37px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
}
.text .button a {
  color: rgba(93, 90, 237, 1);
}
.text .button2 {
  border: 1.5px solid rgba(192, 204, 218, 1);
  color: #9aa7b9;
}
</style>
